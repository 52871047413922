import React from "react";
import { ExpertiseData } from '../data.js';
import "../css/Experties.css";
import "../css/home.css";

export default function Expertise() {

  return (
    <div style={{width:'100%'}}>
      <h2 className="d-flex justify-content-center mt-5 mb-3 text-e1">EXPERTISE</h2>
      <div className="row d-flex justify-content-center cardExperties">
        {ExpertiseData.map((expertise, index) => (
          <div
            key={index}
            className="col-lg-3 col-md-4 col-sm-12 border border-2 border-secondary rounded rounded-4 p-4 m-5">
            <div className="ms-2 d-flex justify-content-center p-5" style={{height:'40%'}}>
              <img src={expertise.img} alt={expertise.name} className="img-e1"/>
            </div>
            <h4 className="d-flex justify-content-center mt-3 text-e2 pt-5">{expertise.name}</h4>
            <p className="d-flex justify-content-center text-ep1 text-center">{expertise.detail}</p>
           
          </div>
        ))}
      </div>
    </div>
  );
}
