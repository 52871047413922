
import './App.css';
import Navbar from './components/navbar';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './components/home';
import Service from './components/service';
import About from './components/about';
import Contact from './components/contact';
import Footer from './components/footer';
import AllService from './components/About/sevice/AllService';
import SeviceDetails from './components/About/sevice/SeviceDetails';
function App() {

  const router = createBrowserRouter([
        {
          path:"/",
          element: <><Navbar /><Home /><Footer/></>
        },
        {
          path:"/about",
          element:<><Navbar /><About /><Footer/></>
        },
        {
          path:"/service",
          element:<><Navbar /><Service /><Footer/></>
        },
    
       
        {
          path:"/contact",
          element:<><Navbar /><Contact /><Footer/></>
        },
         
        {
          path:"/AllService",
          element:<><Navbar /><AllService /><SeviceDetails /><Footer/></>
        },
        
    
  ])

  return (
    <>
    <RouterProvider router={router} />
    </>

  );
}

export default App;
