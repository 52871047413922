import React from "react";

export default function SeviceDetails() {
  return (
    <div>
      <div className="row" style={{ width: "98%" }}>
        <div className="m-lg-5 m-md-3 m-sm-1 col-7">
          <h1 className="mt-3 texth1">MEP BIM Coordination Services</h1>
          <p className="servicetextp1 mt-4">
            {" "}
            and BIM Clash Detection Services. With more than 16 years of
            experience, we have succeeded in executing more than 5000 projects
            and forge lasting partnerships with construction companies globally.
          </p>
          <p className="servicetextp1">
            {" "}
            and BIM Clash Detection Services. With more than 16 years of
            experience, we have succeeded in executing more than 5000 projects
            and forge lasting partnerships with construction companies globally.
          </p>
          <p className="servicetextp1">
            {" "}
            and BIM Clash Detection Services. With more than 16 years of
            experience, we have succeeded in executing more than 5000 projects
            and forge lasting partnerships with construction companies globally.
          </p>
        </div>

        <div className="w-25 col-5 m-auto">
          <img
            src="https://plus.unsplash.com/premium_photo-1682146099355-c45c37b74120?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="mep bim"
            className="serviceimg1"
            style={{ width: "60%" }}
          />
        </div>
      </div>
      <div className="m-lg-5 m-md-3 m-sm-1">
        {" "}
        <p className="servicetextp1 mt-4">
          {" "}
          and BIM Clash Detection Services. With more than 16 years of
          experience, we have succeeded in executing more than 5000 projects and
          forge lasting partnerships with construction companies globally.
        </p>
        <p className="servicetextp1">
          {" "}
          and BIM Clash Detection Services. With more than 16 years of
          experience, we have succeeded in executing more than 5000 projects and
          forge lasting partnerships with construction companies globally.
        </p>
        <p className="servicetextp1">
          {" "}
          and BIM Clash Detection Services. With more than 16 years of
          experience, we have succeeded in executing more than 5000 projects and
          forge lasting partnerships with construction companies globally.
        </p>
      </div>
      
    </div>
  );
}
