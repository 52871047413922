import React from 'react';
import { coreServices } from '../data.js';


const CoreServices = () => {
    return (
        <div style={{ width: '100%' }}>
            <h2 className="text-center mt-5 mb-3 text-e1">Core Services</h2>
            <div className="row justify-content-center cardExperties">
                {coreServices.map((expertise, index) => (
                    <div
                        key={index}
                        className="col-lg-3 col-md-4 col-sm-12 border border-2 border-secondary rounded rounded-4 p-4 m-5"
                    >
                        <div className="d-flex justify-content-center mb-3">
                            <img src={expertise.img} alt={expertise.name} className="img-e1" style={{ maxHeight: '200px' }} />
                        </div>
                        <h4 className="text-center mt-3 mb-3 text-e2">{expertise.name}</h4>
                     
                        <div className="text-ep1 text-center" dangerouslySetInnerHTML={{ __html: expertise.detail }} />
                     
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CoreServices;
