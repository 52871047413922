import React from 'react';
import Estimation from "./pic/EXPERTISE/Estimation & Material Take-off.png";
import Miscellaneous from "./pic/EXPERTISE/Miscellaneous Steel Detailing.jpg";
import Structural from "./pic/EXPERTISE/Structural Steel Detailing.png";
import { Link } from "react-router-dom";
import imge1 from "./pic/EXPERTISE/Estimation & Material Take-off.png";
import imge2 from "./pic/EXPERTISE/Miscellaneous Steel Detailing.jpg";
import imge5 from "./pic/EXPERTISE/Duct Plate work Detailing.jpg";
import imge6 from "./pic/EXPERTISE/Connection Design.jpg";
import imge7 from "./pic/EXPERTISE/Structural Steel Detailing.png";
import imge8 from "./pic/EXPERTISE/Warehouse Shade Detailing.png";
import whyimg1 from './pic/About/i.jpg';
import whyimg2 from './pic/About/o.jpg';
import whyimg3 from './pic/About/r.jpg';
import Industrial from './pic/Key market/k1.png';
import Commercial from './pic/Key market/k2.png';
import Petrochemicals from './pic/Key market/k3.png';
import Residential from './pic/Key market/k4.png';
import pic1 from "./pic/About/pic1.png";
import pic2 from "./pic/About/pic2.png";
import pic3 from "./pic/About/pic3.png";
import pic4 from "./pic/About/pic4.png";
const coreServices = [
    {
        name: "Structural Detailing",
        detail:
            "<ul class='list-group'><li class='list-group-item'>Steel Detailing</li><li class='list-group-item'>Rebar Detailing</li><li class='list-group-item'>Shop Drawing</li><li class='list-group-item'>Connection Design</li><li class='list-group-item'>Precast Panel Detailing</li><li class='list-group-item'>Structural 3D Modeling</li><li class='list-group-item'>Structural 2D Drafting</li></ul>",
        img: Miscellaneous,
    },
    {
        name: "Architectural",
        detail:
            "<ul class='list-group'><li class='list-group-item'>Architectural BIM Services</li><li class='list-group-item'>Scan To BIM Services</li><li class='list-group-item'>Architectural CAD Drafting</li><li class='list-group-item'>Construction Documentation</li><li class='list-group-item'>3D Rendering Services</li><li class='list-group-item'>3D Floor Plan</li><li class='list-group-item'>Revit Family Creation</li><li class='list-group-item'>CAD Services</li><li class='list-group-item'>As Built Documentation</li></ul>",
        img: Structural,
    },
    {
        name: "MEP",
        detail:
            "<ul class='list-group'><li class='list-group-item'>MEP BIM Modeling</li><li class='list-group-item'>MEP BIM Coordination</li><li class='list-group-item'>Clash Detection</li><li class='list-group-item'>MEP Shop Drawings</li><li class='list-group-item'>MEP CAD Drafting</li><li class='list-group-item'>Point Cloud Modeling</li><li class='list-group-item'>Revit Family Creation</li><li class='list-group-item'>Drafting Services</li><li class='list-group-item'>As Built Modeling</li></ul>",
        img: Miscellaneous,
    },
    {
        name: "BIM",
        detail:
            "<ul class='list-group'><li class='list-group-item'>BIM Modeling</li><li class='list-group-item'>BIM Consulting Services</li><li class='list-group-item'>BIM For Infrastructure</li><li class='list-group-item'>Point Cloud To BIM</li><li class='list-group-item'>BIM Coordination</li><li class='list-group-item'>BIM Clash Detection</li><li class='list-group-item'>BIM Content Creation</li><li class='list-group-item'>BIM Shop Drawings</li><li class='list-group-item'>4D BIM Services</li><li class='list-group-item'>Quantity Takeoff</li><li class='list-group-item'>COBie</li><li class='list-group-item'>6D BIM Services</li><li class='list-group-item'>Facility Management</li></ul>",
        img: Estimation,
    },
    {
        name: "Estimation & Material Take-off",
        detail:
            "<ul class='list-group'><li class='list-group-item'>Commercial Estimating</li><li class='list-group-item'>Residential Estimating</li><li class='list-group-item'>Industrial Estimating</li><li class='list-group-item'>Building Estimating</li><li class='list-group-item'>Freelance Estimating</li><li class='list-group-item'>Blueprint Estimating</li><li class='list-group-item'>Electrical Estimating</li><li class='list-group-item'>Outsourcing Estimating</li></ul>",
        img: Estimation,
    },
    {
        name: "Other Services",
        detail:
            "<ul class='list-group'><li class='list-group-item'>Industrial Engineering</li><li class='list-group-item'>Solar panel detailing</li></ul>",
        img: Structural,
    },
];

const ExpertiseData = [
    {
        name: "Structural Steel Detailing",
        detail:
            "EDCAT engineers with deep industry knowledge and who are well trained in all projects. We understand and visualize the real time fabrication and erection complexity and rectify it on time too. Our focus is to create drawings in such a way that the fabricators can easily handle the drawings and complete the job.",
        img: imge7,
        button: <Link to="../EXPERTISE/design.js">Learn More</Link>,
    },
    {
        name: "Miscellaneous Steel Detailing",
        detail:
            "Miscellaneous steel detailing demands a lot of self-decision while working on it, especially in modelling side. Also, this part of detailing demands good technical knowledge as well. Our well experienced team does each job precisely and through best method.",
        img: imge2,
        button: <Link to="../EXPERTISE/design.js">Learn More</Link>,
    },
    {
        name: "Duct / Plate work Detailing",
        detail:
            "Our well experienced team has handled many plate work jobs including large round and rectangular ducts. We have experience of handling Transitions, Concentric cones, Eccentric cones, Chutes, etc. Our services include delivering unfolded DXF files even for transition plates or eccentric cones.",
        img: imge5,
        button: <Link to="../EXPERTISE/SteelDetailing">Learn More</Link>,
    },
    {
        name: "Connection Design",
        detail:
            "Cronus steel detailing offers connection design services to clients through our engineering partners in USA and Canada. In this, both design and detailing come under one roof and thereby it benefits in improved schedule. Also, we offer PE stamping (If contract required).",
        img: imge6,
        button: <Link to="../EXPERTISE/SteelDetailing">Learn More</Link>,
    },
    {
        name: "Warehouse Shade Detailing",
        detail:
            "Warehouse Shade Detailing requires significant independent decision-making, particularly during the modeling phase. This aspect of detailing also necessitates strong technical expertise. Our highly experienced team performs each task with precision, utilizing the best methods available.",
        img: imge8,
        button: <Link to="../EXPERTISE/SteelDetailing">Learn More</Link>,
    },
    {
        name: "Estimation & Material Take-off",
        detail:
            "We have a quick estimation procedure that includes 3D modelling, Material Reports, Complexity study, Material take-off and Man Hour Consumption calculation. On customer's requirement, we can deliver material take off as well.",
        img: imge1,
        button: <Link to="../EXPERTISE/SteelDetailing">Learn More</Link>,
    },
];

const Exp = [
    {
      Headers: "OIL & GAS & POWER",
      Description:
        "<ul><li>Static & Vibrating Equipment Foundations</li><li>Pipe racks/Pipe Bridges</li><li>Process structures like Cooling Towers, Powerhouse </li><li>Equipment supporting steel/concrete structures</li><li>Chimney/Stacks foundation</li><li>Pits, Pond Dike wall</li><li>Steel Tank Roof structure & Foundations</li></ul>",
      img: whyimg1,
    },
    {
      Headers: "INDUSTRIAL",
      Description:
        "<ul><li>Industrial Sheds</li><li>Process Buildings</li><li>Conveyor System</li></ul>",
      img: whyimg2,
    },
    {
      Headers: "RESIDENTIAL",
      Description:
        "<ul><li>COST ESTIMATION</li><li>BOQ/MATERIAL TAKE-OFF</li></ul>",
      img: whyimg3,
    },
  ];

  const Markets = [{
    name: "Industrial",
    img: Industrial
}, {
    name: "Commercial",
    img: Commercial
}, {
    name: "Oil & Gass / Petrochemicals",
    img: Petrochemicals
}, {
    name: "Residential",
    img: Residential
}];
const Approachdata = [
    {
      img1: pic1,
      Header: "Understanding Client Requirements:",
      description: "We recognize that each client and project demand a unique approach, often requiring a new set of skills and technologies. We dedicate the necessary time to thoroughly understand the project's objectives.",
    },
    {
      img1: pic2,
      Header: "Delivering Solutions:",
      description: "To achieve project goals, our engineers adopt the most appropriate methods, consistently surpassing expectations. Our work follows a system-driven process, incorporating the latest BIM industry methods, ensuring projects are delivered on time and of the highest quality.",
    },
    {
      img1: pic3,
      Header: "Continuous Improvement:",
      description: "Led by highly capable and seasoned project managers, our engineering team continuously learns, researches, and updates their skills to meet the dynamic demands of the AEC industry. Systematic knowledge sharing and perfection of our work processes are ongoing practices at EDCAT. With every project, we ensure there is always room for innovation.",
    },
    {
      img1: pic4,
      Header: "Our Promise:",
      description: "Client satisfaction is our guaranteed promise, and we measure our success by the success of our clients. While we take pride in our past achievements, we view them as milestones in our ongoing pursuit of excellence.",
    },
  ];
  const keyValues = [
    {
        key: "Customer Satisfaction:",
        describe: "Consistently surpassing client expectations."
    },
    {
        key: "Standards:",
        describe: "Establishing exemplary standards in all our business dealings."
    },
    {
        key: "Integrity:",
        describe: "Maintaining sincerity, ethics, and transparency in all transactions."
    },
    {
        key: "Fairness:",
        describe: "Treating all clients equally, from large corporations to small businesses."
    },
    {
        key: "Technology Update:",
        describe: "Continuously updating our skills and technology to stay at the forefront of the industry."
    }
];
const VDM_BIM_CAD = [
    {
      name: "Structural",
      detail: "<ul type='none' class='p-0'><li>Steel Detailing</li><li>Rebar Detailing</li><li>Shop Drawing</li><li>Connection Design</li><li>Precast Panel Detailing</li><li>Structural 3D Modeling</li><li>Structural 2D Drafting</li></ul>",
    },
    {
      name: "Architectural",
      detail: "<ul type='none' class='p-0'><li>Architectural BIM Services</li><li>Scan To BIM Services</li><li>Architectural CAD Drafting</li><li>Construction Documentation</li><li>3D Rendering Services</li><li>3D Floor Plan</li><li>Revit Family Creation</li><li>CAD Services</li><li>As Built Documentation</li></ul>",
    },
    {
      name: "MEP",
      detail: "<ul type='none' class='p-0'><li>MEP BIM Modeling</li><li>MEP BIM Coordination</li><li>Clash Detection</li><li>MEP Shop Drawings</li><li>MEP CAD Drafting</li><li>Point Cloud Modeling</li><li>Revit Family Creation</li><li>Drafting Services</li><li>As Built Modeling</li></ul>",
    },
    {
      name: "BIM",
      detail: "<ul type='none' class='p-0'><li>BIM Modeling</li><li>BIM Consulting Services</li><li>BIM For Infrastructure</li><li>Point Cloud To BIM</li><li>BIM Coordination</li><li>BIM Clash Detection</li><li>BIM Content Creation</li><li>BIM Shop Drawings</li><li>4D BIM Services</li><li>Quantity Takeoff</li><li>COBie</li><li>6D BIM Services</li><li>Facility Management</li></ul>",
    },
    {
      name: "Estimation & Material Take-off",
      detail: "<ul type='none' class='p-0'><li>Commercial Estimating</li><li>Residential Estimating</li><li>Industrial Estimating</li><li>Building Estimating</li><li>Freelance Estimating</li><li>Blueprint Estimating</li><li>Electrical Estimating</li><li>Outsourcing Estimating</li></ul>",
    },
  
  ];
  const EngineeringConsulting = [
    {
      name: "Civil Engineering Services",
      detail: "<ul type='none' class='p-0'><li>Designs and Calculations</li><li>Engineering Work Packages</li><li>Engineering Compliance Drawings</li><li>Engineering Check and Code Compliance</li><li>Cost Estimates</li></ul>",
    },
    {
      name: "Structural Engineering Services",
      detail: "<ul type='none' class='p-0'><li>Structural Design</li><li>Structural 3D mode</li><li>Dynamic analysis of equipment foundation</li><li>Work Packages</li></ul>",
    },
    {
      name: "Drafting and Design Engineering Consultants",
      detail: "<ul type='none' class='p-0'><li>Civil Structural</li></ul>",
    },
  ];



export { ExpertiseData, coreServices, Exp, Markets , Approachdata, keyValues,VDM_BIM_CAD,EngineeringConsulting };
