import React  from 'react';
import '../css/Market.css';
import '../css/home.css';
import { Markets } from '../data.js';
export default function Market() {


    return (
        <div className='body p-5'>
            <h2 className="text-3xl d-flex justify-content-center">KEY MARKETS</h2>
            <div className='row p-1' style={{ width: '100%' }}>
                {Markets.map((market, index) => (
                    <div key={index} className='col'>
                        <div className='img1 d-flex justify-content-center mt-4'>
                            <img src={market.img} alt={market.name} style={{width:'40%'}} /> {/* Using img tag */}
                        </div>
                        <h4 className='text-2xl d-flex justify-content-center mt-3'>{market.name}</h4>
                    </div>
                ))}
            </div>
        </div>
    );
}
